import React from 'react';
import { StaticImageData } from 'next/image';
import edicao_002 from 'public/pdfs/jun_2023/edicao_002.png';
import edicao_003 from 'public/pdfs/jul_2023/edicao_003.png';
import edicao_001 from 'public/pdfs/mai_2023/edicao_001.png';
import edicao_004 from 'public/pdfs/ago_2023/edicao_004.png';
import edicao_005 from 'public/pdfs/set_2023/edicao_005.png';
import edicao_006 from 'public/pdfs/out_2023/edicao_006.png';
import edicao_007 from 'public/pdfs/nov_2023/edicao_007.png';
import edicao_008 from 'public/pdfs/dez_2023/edicao_008.png';
import edicao_009 from 'public/pdfs/jan_2024/Boletim_ABCR+_Edição_009_Jan_24.png';
import edicao_010 from 'public/pdfs/fev_2024/fev_2024.png';
import edicao_011 from 'public/pdfs/mar_2024/image.jpg';
import edicao_012 from 'public/pdfs/abril_2024/image.png';
import edicao_013 from 'public/pdfs/maio_2024/imageMaio2024.jpg';
import edicao_014 from 'public/pdfs/jun_2024/img.png';
import edicao_015 from 'public/pdfs/jul_2024/boletim.png';
import edicao_016 from 'public/pdfs/ago_2024/boletim.png';
import edicao_017 from 'public/pdfs/set_2024/img.png';
import edicao_018 from 'public/pdfs/out_2024/imagem.png';

type SetState<T> = React.Dispatch<React.SetStateAction<T>>;

interface PdfObjectProps {
  alt: string;
  url: string;
  text: string;
  imageUrl: StaticImageData;
}

interface ReportAbcrProps {
  handleChangeOptionsInput: (e: any, image: StaticImageData) => void;
  item: any;
  openInput: boolean;
  selectedItemInput: string;
  inputItems: PdfObjectProps[];
  setOpenInput: SetState<boolean>;
  selectedItemImage: StaticImageData;
};

export const ReportAbcrContext = React.createContext({} as ReportAbcrProps);

interface IProps {
  children: React.ReactNode;
}

const PdfMockObject = [
  {
    alt: 'edicao_001',
    imageUrl: edicao_001,
    text: 'Edição 001 | Mai/2023',
    url: 'pdfs/mai_2023/edicao_001_mai_2023.pdf',
  },
  {
    alt: 'edicao_002',
    imageUrl: edicao_002,
    text: 'Edição 002 | Jun/2023',
    url: 'pdfs/jun_2023/edicao_002_jun_2023.pdf',
  },
  {
    alt: 'edicao_003',
    imageUrl: edicao_003,
    text: 'Edição 003 | Jul/2023',
    url: 'pdfs/jul_2023/edicao_003_jul_2023.pdf',
  },
  {
    alt: 'edicao_004',
    imageUrl: edicao_004,
    text: 'Edição 004 | Ago/2023',
    url: 'pdfs/ago_2023/edicao_004_ago_2023.pdf',
  },
  {
    alt: 'edicao_005',
    imageUrl: edicao_005,
    text: 'Edição 005 | Set/2023',
    url: 'pdfs/set_2023/edicao_005_set_2023.pdf',
  },
  {
    alt: 'edicao_006',
    imageUrl: edicao_006,
    text: 'Edição 006 | Out/2023',
    url: 'pdfs/out_2023/edicao_006_out_2023.pdf',
  },
  {
    alt: 'edicao_007',
    imageUrl: edicao_007,
    text: 'Edição 007 | Nov/2023',
    url: 'pdfs/nov_2023/Boletim_ABCR+_Edição_007_Nov_23_vf.pdf',
  },
  {
    alt: 'edicao_008',
    imageUrl: edicao_008,
    text: 'Edição 008 | Dez/2023',
    url: 'pdfs/dez_2023/Boletim_ABCR _Edição_008_Dez _23.pdf',
  },
  {
    alt: 'edicao_009',
    imageUrl: edicao_009,
    text: 'Edição 009 | Jan/2024',
    url: 'pdfs/jan_2024/Boletim_ABCR+_Edição_009_Jan_24_vf.pdf',
  },
  {
    alt: 'edicao_010',
    imageUrl: edicao_010,
    text: 'Edição 010 | Fev/2024',
    url: 'pdfs/fev_2024/Boletim_ABCR+_Edição_010_Fev_24_vf.pdf',
  },
  {
    alt: 'edicao_011',
    imageUrl: edicao_011,
    text: 'Edição 011 | Mar/2024',
    url: 'pdfs/mar_2024/Boletim_ABCR+_Edição_011_Mar_24_vf.pdf',
  },
  {
    alt: 'edicao_012',
    imageUrl: edicao_012,
    text: 'Edição 012 | Abril/2024',
    url: 'pdfs/abril_2024/Boletim_ABCR+_Edição_012_Abr_24_vf.pdf',
  },
  {
    alt: 'edicao_013',
    imageUrl: edicao_013,
    text: 'Edição 013 | Maio/2024',
    url: 'pdfs/maio_2024/Boletim_ABCR+_Edição_013_Maio_24_vf.pdf',
  },
  {
    alt: 'edicao_014',
    imageUrl: edicao_014,
    text: 'Edição 014 | Junho/2024',
    url: 'pdfs/jun_2024/Boletim_ABCR+_Edição_014_Junho_24_vf.pdf',
  },
  {
    alt: 'edicao_015',
    imageUrl: edicao_015,
    text: 'Edição 015 | Julho/2024',
    url: 'pdfs/jul_2024/pdf.pdf',
  },
  {
    alt: 'edicao_016',
    imageUrl: edicao_016,
    text: 'Edição 016 | Agosto/2024',
    url: 'pdfs/ago_2024/pdf.pdf',
  },
  {
    alt: 'edicao_017',
    imageUrl: edicao_017,
    text: 'Edição 017 | Setembro/2024',
    url: 'pdfs/set_2024/bpdf.pdf',
  },
  {
    alt: 'edicao_018',
    imageUrl: edicao_018,
    text: 'Edição 018 | Outubro/2024',
    url: 'pdfs/out_2024/pdf.pdf',
  }

];


export const ReportAbcrContextProvider: React.FC<IProps> = ({ children }) => {
  const [openInput, setOpenInput] = React.useState(false);
  const [selectedItemInput, setSelectedItemInput] = React.useState("Selecionar edição");
  const [selectedItemImage, setSelectedItemImage] = React.useState({} as StaticImageData);

  const item = React.useMemo(() =>
    PdfMockObject.find(item => item.text === selectedItemInput), [selectedItemInput]);

  const handleChangeOptionsInput = (e: any, image: StaticImageData) => {
    setSelectedItemInput(e);
    setOpenInput(!openInput);
    setSelectedItemImage(image);
  };

  const value = {
    item,
    openInput,
    setOpenInput,
    selectedItemInput,
    selectedItemImage,
    handleChangeOptionsInput,
    inputItems: PdfMockObject,
  };

  return <ReportAbcrContext.Provider value={value}>{children}</ReportAbcrContext.Provider>;
};